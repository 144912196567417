import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { StaticImage } from 'gatsby-plugin-image';

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  width: "300px",
  textAlign: 'left',
  flexGrow: 1
}));


export default function HeaderGrid() {
  return (
    <Box>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        sx={{ flexWrap: 'wrap' }}
      >
        <Item>
          <StaticImage src={'../../../images/nesisai-productity-333x500.png'}  alt='' />
        </Item>
        <Item>
          <Typography variant="h4" gutterBottom><strong>Maximize Your Productivity</strong></Typography>
          <Typography variant="h6">
            By automating information retrieval and analysis, NesisAI slashes the time and effort required to find and interpret critical data.
            Imagine your employees making faster, smarter decisions—unlocking the full potential of your business.
          </Typography>
        </Item>
      </Stack>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="col"
        useFlexGap
        sx={{ flexWrap: 'wrap' }}
      >
        <Item>
          <Typography variant="h4" gutterBottom>
            <strong>Elevate Your Enterprise</strong>
          </Typography>
          <Typography variant="h6">
            Seamlessly integrate information from multiple sources across your organization and unlock a unified view that revolutionizes knowledge sharing and collaboration. Don’t let fragmented data hold you back—empower your team with the insights they need to drive innovation and make smarter decisions.
          </Typography>
        </Item>

        <Item>
          <StaticImage src={'../../../images/nesisai-enterprise-333x500.png'} alt=''  />
        </Item>

      </Stack>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="col"
        useFlexGap
        sx={{ flexWrap: 'wrap' }}
      >
        <Item>
          <StaticImage src={'../../../images/nesisai-security-333x500.png'} alt=''  />
        </Item>
        <Item>
          <Typography variant="h4" gutterBottom><strong>Secure Your Data</strong></Typography>
          <Typography variant="h6">
            Whether you deploy on your local servers or private cloud, NesisAI guarantees that your data remains fully secure within your infrastructure. Experience the peace of mind that comes with full <b>data sovereignty</b>, ensuring compliance with all relevant laws, regulations, and regional requirements. Plus, with our advanced <b>Role-Based Access Control (RBAC)</b>, you’ll benefit from top-tier security and seamless management.
          </Typography>
        </Item>

      </Stack>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="col"
        useFlexGap
        sx={{ flexWrap: 'wrap' }}
      >
        <Item>
          <Typography variant="h4" gutterBottom><strong>Extensive API</strong></Typography>
          <Typography variant="h6">
            NesisAI empowers you to bring AI capabilities to your existing applications. Use cases include
            <ul>
              <li>
                <b>Finance:</b> Give your mobile banking app generative AI capabilities to help your customers to interract with their bank statements.
              </li>
              <li>
                <b>Website:</b> Add generative AI powered interraction to your website so that users have tailored responses based on their personalized data.
              </li>
            </ul>
          </Typography>
        </Item>
        <Item>
          <StaticImage src={'../../../images/nesisai-api-333x500.png'} alt=''  />
        </Item>
      </Stack>
    </Box>
  );
}
